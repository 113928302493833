<template>
    <div class="col">
        <div class="menu-item shadow p-4 rounded text-center d-flex flex-column"
            :style="{ '--icon-color': iconColor, '--hover-color': hoverColor }">
            <router-link :to="path"
                class="text-decoration-none h-100 d-flex flex-column justify-content-between align-items-center">
                <div class="icon-wrapper mb-3">
                    <i :class="icon" class="icon"></i>
                </div>
                <figcaption class="figure-caption fw-bold text-center" v-html="caption"></figcaption>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "MenuItem",
    props: {
        path: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        altText: {
            type: String,
            required: false,
        },
        caption: {
            type: String,
            required: true,
        },
        iconColor: {
            type: String,
            required: true,
        },
        hoverColor: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
/* Menu Item */
.menu-item {
    height: 100%;
    min-height: 200px;
    /* Consistent tile height */
    background-color: white;
    border: 2px solid transparent;
    transition: transform 0.3s, box-shadow 0.3s, border-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Hover effect: dynamic color for shadow and border */
.menu-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px var(--hover-color);
    border-color: var(--hover-color);
}

/* Icon Styling */
.icon-wrapper {
    font-size: 2.5rem;
    line-height: 1;
    color: var(--icon-color);
}

.icon {
    display: block;
}

.menu-item figcaption {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #343a40;
    /* Dark text */
}
</style>