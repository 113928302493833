import { render, staticRenderFns } from "./CafeMenuItems.vue?vue&type=template&id=6bc026fd&scoped=true"
import script from "./CafeMenuItems.vue?vue&type=script&lang=js"
export * from "./CafeMenuItems.vue?vue&type=script&lang=js"
import style0 from "./CafeMenuItems.vue?vue&type=style&index=0&id=6bc026fd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc026fd",
  null
  
)

export default component.exports