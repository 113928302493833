import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Menu from '../views/Menu.vue'
import Records from '../views/Records.vue'
import Records_printing from '../views/Records_printing.vue'
import Salaries from '../views/Salaries.vue'
import HR from '../views/HR.vue'
import Details from '../views/details.vue'
import Salaries_quanzaine from '../views/Salaries_quanzaine.vue'
import Reductions from '../views/Reductions.vue'
import CafeMenuItems from '../views/CafeMenuItems.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'any',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/',
    name: 'home',
    component: Login

  }
  ,
  {
    path: '/menu',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/records',
    name: 'Records',
    component: Records
  },{
    path: '/remises',
    name: 'Remise',
    component: Reductions
  },
  {
    path: '/records_printing',
    name: 'Records_printing',
    component: Records_printing
  },
  {
    path: '/salaries',
    name: 'Salaries',
    component: Salaries
  },
  {
    path: '/salaries_quanz',
    name: 'SalariesQuanz',
    component: Salaries_quanzaine
  },
  {
    path: '/hr',
    name: 'HR',
    component: HR
  },
  {
    path: '/Details',
    name: 'Details',
    component: Details
  },
  {
    path: '/CafeMenu',
    name: 'Cafe Menu Items',
    component: CafeMenuItems
  }
]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/'];
  //const acceptedPages = ['/login', '/',"/menu",'/records','/records_printing','/salaries','/hr'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if ((authRequired && !loggedIn) ) {
    console.log("not authed")
    next({ name: 'home' });
  } else {
    console.log(" authed")

    next();
  }
});

export default router
