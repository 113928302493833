<template>
  <div id="elements" class="">
    <div id="research" class="mb-5">
      <b-row>
        <b-col cols="12" lg="3">
          <label for="from_date">Date de début</label>
          <b-form-datepicker id="from_date" today-button locale="fr" label-today-button="Aujourd'hui"
            v-model="from_date"></b-form-datepicker>

          <label for="to_date" class="mt-3">Date de fin</label>
          <b-form-datepicker id="to_date" today-button locale="fr" label-today-button="Aujourd'hui"
            v-model="to_date"></b-form-datepicker>
        </b-col>

        <b-col cols="12" lg="3">
          <label for="type">Type</label>
          <v-select id="type" v-model="part" :options="parts" placeholder="Sélectionnez un type">
            <div slot="no-options">Désolé, aucune option correspondante.</div>
          </v-select>

          <label for="radios" class="mt-3">Type de recette</label>
          <b-form-radio-group id="radios" class="d-block" v-model="selected_radio" :options="options_radio" buttons
            button-variant="outline-primary"></b-form-radio-group>
        </b-col>

        <b-col cols="12" lg="3">
          <label>Actions</label>
          <b-button block variant="success" @click="search">Rechercher</b-button>
          <b-button block variant="info" class="mt-2" @click="print">Imprimer</b-button>
        </b-col>

        <b-col cols="12" lg="3">
          <label>Résumé</label>
          <b-button block variant="warning" @click="recap_printing">Récapitulatif</b-button>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-jumbotron>
        <b-row>
          <b-col>
            <b-table class="table-hover myTable" :fields="fields" :items="ops" hover responsive>
              <template v-slot:thead-top>
                <b-tr>
                  <b-th colspan="4"></b-th>
                  <b-th colspan="2" class="text-center">Montants</b-th>
                </b-tr>
              </template>

              <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
              <template v-slot:cell(dep)="data">
                <b>{{ data.value ? formatNumber(data.value) : "" }}</b>
              </template>
              <template v-slot:cell(rec)="data">
                <b>{{ data.value ? formatNumber(data.value) : "" }}</b>
              </template>
              <template v-slot:cell(date_op)="data">{{ convert_to_date(data.value) }}</template>

              <template v-slot:custom-foot>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th class="table-success">Recettes</b-th>
                  <b-th class="text-right">{{ formatNumber(recettes_mnt) }}</b-th>
                </b-tr>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th class="table-danger">Dépenses</b-th>
                  <b-th class="text-right">{{ formatNumber(depenses_mnt) }}</b-th>
                </b-tr>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th class="table-info">Solde</b-th>
                  <b-th class="text-right">{{ formatNumber(recettes_mnt - depenses_mnt) }}</b-th>
                </b-tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-jumbotron>
    </div>
  </div>
</template>
<script>
const pdfFonts = {
  // download default Roboto font from cdnjs.com
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};
export default {
  data() {
    return {
      from_date: this.moment_date(),
      to_date: this.moment_date(),
      part: "",
      parts: [],
      ops: [],
      selected_radio: "",
      options_radio: [
        { text: "Recettes", value: "Recette" },
        { text: "Les deux", value: "" },
        { text: "Dépenses", value: "Depense" },
      ],
      fields: [
        "#",
        { key: "part", label: "Type", sortable: true },
        { key: "motif_op", label: "Utilisateur", sortable: true },
        { key: "date_op", label: "Date", sortable: true },
        { key: "dep", label: "Dépenses", sortable: true, tdClass: "text-right" },
        { key: "rec", label: "Recettes", sortable: true, tdClass: "text-right" },
      ],
    };
  },
  methods: {
    formatNumber: function (value) {
      if (isNaN(value)) return value;
      return new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    moment_date(date) {
      const moment = require("moment");
      return moment(date).format("YYYY-MM-DD");
    },
    convert_to_date(value) {
      const moment = require("moment");
      return moment(value).format("DD/MM/YYYY");
    },
    search() {
      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/operations/get_operations",
          {
            ldate: this.moment_date(this.from_date),
            rdate: this.moment_date(this.to_date),
            part: this.part,
            type: this.selected_radio,
          },
          { headers: { Authorization: "Bearer " + this.$store.state.token } }
        )
        .then((response) => {
          this.ops = response.data.map((el, index) => {
            el.index = index;
            if (el.type == "Depense") el.dep = el.mnt;
            else el.rec = el.mnt;
            return el;
          });
        })
        .catch((err) => console.error(err));
    },
    print: function () {
      var pdfMake = require("pdfmake/build/pdfmake.min.js");
      // PDF Fonts
      //var myWindow = window.open("", "", "fullscreen=on");
      var win = window.open(
        "",
        "_blank",
        "width=757,height=1000,menubar=no,status=no"
      );
      // var logo = document.getElementById("logo");
      // var canvas = document.createElement("canvas");
      // canvas.width = logo.width;
      // canvas.height = logo.height;
      // canvas.getContext("2d").drawImage(logo, 0, 0);

      var docDefinition = {
        content: [
          /* {
            alignment: "justify",
            columns: [
              {
                width: 80,
                image: canvas.toDataURL("image/png")
              },
              {
                //width: "*",

                //alignment: "right",
                text: [
                  {
                    text: "Ain Salama",
                    bold: true,
                    fontSize: 25,
                    color: "#e6853c"
                  },
                  {
                    text: "\nSource Thermal",
                    fontSize: 16,
                    bold: true,
                    color: "#34ed59"
                  },
                  {
                    text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                    italics: true
                  }
                ]
              }
            ]
          },*/
          {
            text:
              "Recapitulation : " +
              this.convert_to_date(this.from_date) +
              " - " +
              this.convert_to_date(this.to_date),
            decoration: "underline",
            alignment: "center",
            fontSize: 20,
            bold: true,
            margin: [0, 20],
          },
          {
            table: {
              headerRows: 2,
              widths: [30, "*", "*", "*", "*", "*"],
              body: [
                [
                  { text: "", colSpan: 4 },
                  {},
                  {},
                  {},
                  { text: "Montant", alignment: "center", colSpan: 2 },
                  {},
                ],
                [
                  { text: "N", alignment: "center" },
                  "Type",
                  "Utilisateur",
                  "Date",
                  "Depenses",
                  "Recettes",
                ],
                ...this.ops.map((el, index) => [
                  { text: index + 1, alignment: "right" },
                  el.part,
                  el.motif_op,
                  this.convert_to_date(el.date_op),
                  {
                    text: el.type != "Recette" ? el.mnt.toFixed(2) : "",
                    alignment: "right",
                  },
                  {
                    text: el.type == "Recette" ? el.mnt.toFixed(2) : "",
                    alignment: "right",
                  },
                ]),
                [
                  { text: "", colSpan: 5, fillColor: "#575550" },
                  {},
                  {},
                  {},
                  {},
                  {},
                ],
                [
                  { text: "", colSpan: 2 },
                  {},
                  {},
                  {
                    text: "Total des Recettes ",
                    alignment: "left",
                    colSpan: 2,
                  },
                  {},
                  { text: this.recettes_mnt.toFixed(2), alignment: "right" },
                ],
                [
                  { text: "", colSpan: 2 },
                  {},
                  {},
                  {
                    text: "Total des Depenses",
                    alignment: "left",
                    colSpan: 2,
                  },
                  {},
                  { text: this.depenses_mnt.toFixed(2), alignment: "right" },
                ],
                [
                  { text: "", colSpan: 2 },
                  {},
                  {},
                  { text: "Solde", alignment: "left", colSpan: 2 },
                  {},
                  {
                    text: (this.recettes_mnt - this.depenses_mnt).toFixed(2),
                    alignment: "right",
                  },
                ],
              ],
            },
          },
          /* {
             text: {
               text: "Validation",
               decoration: "underline",
               alignment: "right",
             },
             margin: [35, 20],
           },*/
        ],
        defaultStyle: {
          columnGap: 25,
        },
      };
      pdfMake.createPdf(docDefinition, null, pdfFonts).open({}, win);
    },
    recap_printing: function () {
      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/operations/get_recap_noff",
          {
            ldate: this.moment_date(this.from_date),
            rdate: this.moment_date(this.to_date),
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          var rct_mnt = data
            .filter((el) => el.type == "Recette")
            .reduce((a, b) => a + b.mnt, 0);
          var dps_mnt = data
            .filter((el) => el.type == "Depense")
            .reduce((a, b) => a + b.mnt, 0);

          var pdfMake = require("pdfmake/build/pdfmake.min.js");

          //var myWindow = window.open("", "", "fullscreen=on");
          var win = window.open(
            "",
            "_blank",
            "width=757,height=1000,menubar=no,status=no"
          );
          // var logo = document.getElementById("logo");
          // var canvas = document.createElement("canvas");
          // canvas.width = logo.width;
          // canvas.height = logo.height;
          // canvas.getContext("2d").drawImage(logo, 0, 0);

          var docDefinition = {
            content: [
              /* {
            alignment: "justify",
            columns: [
              {
                width: 80,
                image: canvas.toDataURL("image/png")
              },
              {
                //width: "*",

                //alignment: "right",
                text: [
                  {
                    text: "Ain Salama",
                    bold: true,
                    fontSize: 25,
                    color: "#e6853c"
                  },
                  {
                    text: "\nSource Thermal",
                    fontSize: 16,
                    bold: true,
                    color: "#34ed59"
                  },
                  {
                    text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                    italics: true
                  }
                ]
              }
            ]
          },*/
              {
                text:
                  "Recapitulation : " +
                  this.convert_to_date(this.from_date) +
                  " - " +
                  this.convert_to_date(this.to_date),
                decoration: "underline",
                alignment: "center",
                fontSize: 20,
                bold: true,
                margin: [0, 20],
              },
              {
                table: {
                  headerRows: 2,
                  widths: [30, "*", "*", "*", "*"],
                  body: [
                    [
                      { text: "", colSpan: 3 },
                      {},
                      {},
                      { text: "Montant", alignment: "center", colSpan: 2 },
                      {},
                    ],
                    [
                      { text: "N", alignment: "center" },
                      "Type",
                      "Utilisateur",
                      "Depenses",
                      "Recettes",
                    ],
                    ...data.map((el, index) => [
                      { text: index + 1, alignment: "right" },
                      el.part,
                      el.motif_op,
                      {
                        text: el.type != "Recette" ? el.mnt.toFixed(2) : "",
                        alignment: "right",
                      },
                      {
                        text: el.type == "Recette" ? el.mnt.toFixed(2) : "",
                        alignment: "right",
                      },
                    ]),
                    [
                      { text: "", colSpan: 4, fillColor: "#575550" },
                      {},
                      {},
                      {},
                      {},
                    ],
                    [
                      { text: "", colSpan: 2 },
                      {},
                      {},
                      {
                        text: "Total des Recettes ",
                        alignment: "left",
                        colSpan: 1,
                      },
                      {
                        text: rct_mnt.toFixed(2),
                        alignment: "right",
                      },
                    ],
                    [
                      { text: "", colSpan: 2 },
                      {},
                      {},
                      {
                        text: "Total des Depenses",
                        alignment: "left",
                        colSpan: 1,
                      },
                      {
                        text: dps_mnt.toFixed(2),
                        alignment: "right",
                      },
                    ],
                    [
                      { text: "", colSpan: 2 },
                      {},
                      {},
                      { text: "Solde", alignment: "left", colSpan: 1 },
                      {
                        text: (rct_mnt - dps_mnt).toFixed(2),
                        alignment: "right",
                      },
                    ],
                  ],
                },
              },
              /* {
                 text: {
                   text: "Validation",
                   decoration: "underline",
                   alignment: "right",
                 },
                 margin: [35, 20],
               },*/
            ],
            defaultStyle: {
              columnGap: 25,
            },
          };
          pdfMake.createPdf(docDefinition, null, pdfFonts).open({}, win);

          //this.isbusy = false;
        })
        .catch((err) => {
          if (err.response &&  err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },

  },
  computed: {
    recettes() {
      return this.ops.filter((el) => el.type == "Recette");
    },
    depenses() {
      return this.ops.filter((el) => el.type == "Depense");
    },
    recettes_mnt() {
      return this.recettes.reduce((sum, el) => sum + el.mnt, 0);
    },
    depenses_mnt() {
      return this.depenses.reduce((sum, el) => sum + el.mnt, 0);
    },
  },
};
</script>
<style scoped>
#elements {
  margin-top: 3%;
}

.table-hover {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

b-table .text-right {
  text-align: right;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}
</style>
