<template>
  <div id="elements" class="container-fluid">
    <div id="research" class="mb-4">
      <b-row>
        <b-col cols="12" lg="3" class="mb-3">
          <label for="ldate">Date de début</label>
          <b-form-datepicker today-button locale="fr" label-today-button="Aujourd'hui"
            v-model="from_date"></b-form-datepicker>
          <label for="ldate" class="mt-3">Date de fin</label>
          <b-form-datepicker today-button locale="fr" label-today-button="Aujourd'hui"
            v-model="to_date"></b-form-datepicker>
        </b-col>

        <b-col cols="12" lg="3" class="mb-3">
          <b-form-group label="Type de recette" class="mb-3">
            <b-form-radio-group v-model="selected_radio" :options="options_radio" buttons
              button-variant="outline-primary" name="radios-btn-default"></b-form-radio-group>
          </b-form-group>

          <label for="ldate">Actions</label>
          <b-button block variant="success" class="mt-2" @click="search">
            Rechercher
          </b-button>
        </b-col>

        <b-col cols="12" lg="3" offset-lg="3" class="mb-3">
          <table id="totals" class="table table-bordered">
            <tbody>
              <tr class="table-success">
                <th>Nombre de Tickets</th>
                <td>{{ items.length }}</td>
              </tr>
              <tr class="table-danger" v-if="selected_radio !== 'cafe'">
                <th>Nombre de Personnes</th>
                <td>{{ nummberPersons }}</td>
              </tr>
              <tr class="table-warning" v-if="selected_radio === 'hammam'">
                <th>Nombre de Remises</th>
                <td>{{ nummberRemises }}</td>
              </tr>
              <tr class="table-info">
                <th>Total</th>
                <td>{{ formatNumber(total_price) }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-jumbotron>
        <b-alert show variant="success" class="text-center mb-4">
          Caisse du : {{ selected_radio === "piscine" ? "Piscine d'été" : selected_radio }}
        </b-alert>

        <b-row v-if="selected_radio === 'piscine_cov'" class="mb-4">
          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill text-center" variant="primary">
                Hommes
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill" variant="primary">
                +10 ans : {{ nombrePiscineCov.h.cat_1 }}
              </b-list-group-item>
              <b-list-group-item class="flex-fill" variant="primary">
                7-10 ans : {{ nombrePiscineCov.h.cat_2 }}
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill" variant="primary">
                2-7 ans : {{ nombrePiscineCov.h.cat_3 }}
              </b-list-group-item>
              <b-list-group-item class="flex-fill" variant="primary">
                -2 ans : {{ nombrePiscineCov.h.cat_4 }}
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill text-center" variant="danger">
                Femmes
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill" variant="danger">
                +10 ans : {{ nombrePiscineCov.f.cat_1 }}
              </b-list-group-item>
              <b-list-group-item class="flex-fill" variant="danger">
                7-10 ans : {{ nombrePiscineCov.f.cat_2 }}
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill" variant="danger">
                2-7 ans : {{ nombrePiscineCov.f.cat_3 }}
              </b-list-group-item>
              <b-list-group-item class="flex-fill" variant="danger">
                -2 ans : {{ nombrePiscineCov.f.cat_4 }}
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill text-center" variant="success">
                Total
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill" variant="success">
                +10 ans : {{ nombrePiscineCov.h.cat_1 + nombrePiscineCov.f.cat_1 }}
              </b-list-group-item>
              <b-list-group-item class="flex-fill" variant="success">
                7-10 ans : {{ nombrePiscineCov.h.cat_2 + nombrePiscineCov.f.cat_2 }}
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-list-group horizontal>
              <b-list-group-item class="flex-fill" variant="success">
                2-7 ans : {{ nombrePiscineCov.h.cat_3 + nombrePiscineCov.f.cat_3 }}
              </b-list-group-item>
              <b-list-group-item class="flex-fill" variant="success">
                -2 ans : {{ nombrePiscineCov.h.cat_4 + nombrePiscineCov.f.cat_4 }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>

        <!-- Table -->
        <div>
          <b-table sticky-header responsive :fields="selected_fields" :items="items" :tbody-tr-class="rowClass" hover
            head-variant="dark">
            <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
            <template v-slot:cell(gender)="data">
              {{ data.item.gender === "F" ? "Femme" : "Homme" }}
            </template>
            <template v-slot:cell(discount)="data">
              {{ Math.floor(data.value * 100) }}%
            </template>
            <template v-slot:cell(action)="row">
              <b-button @click="showDetails(row.item)">Afficher les Détails</b-button>
            </template>
          </b-table>
        </div>
      </b-jumbotron>
    </div>

    <!-- Modal -->
    <b-modal id="cafeDetailsModal" size="xl" title="Détails de la commande" header-text-variant="dark">
      <b-container fluid>
        <b-table :items="cafeorder_items" :fields="cafeFields">
          <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
          <template v-slot:cell(prix)="data">
            {{ formatNumber(data.item.qtt * data.item.price) }}
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>


<script>
export default {
  name: "Details-component",
  data() {
    return {
      to_date: this.moment_date(),
      from_date: this.moment_date(),
      cafeorder_items: [],
      selected_radio: "hammam",
      options_radio: [
        { text: "Hammam", value: "hammam" },
        { text: "Piscine Couverte", value: "piscine_cov" },
        { text: "Piscine Non-couverte", value: "piscine" },
        { text: "Café", value: "cafe" },
      ],
      items: [],
      fields: [
        [
          "#",
          { label: "Vendeur", key: "Nom_complet", sortable: true },
          { label: "Date", key: "rec_date", sortable: true },
          { label: "Type", key: "gender", sortable: true },
          { label: "Collectifs", key: "coll", sortable: true },
          { label: "Individuels", key: "indi", sortable: true },
          { label: "Enfants", key: "enf", sortable: true },
          { label: "Remise", key: "remise", sortable: true },
          { label: "Prix Total", key: "ttl", sortable: true },
        ],
        [
          "#",
          { label: "Vendeur", key: "Nom_complet", sortable: true },
          { label: "Date", key: "rec_date", sortable: true },
          { label: "Type", key: "gender", sortable: true },
          { label: "+10", key: "cat_1", sortable: true },
          { label: "7-10", key: "cat_2", sortable: true },
          { label: "2-7", key: "cat_3", sortable: true },
          { label: "-2", key: "cat_4", sortable: true },
          { label: "remise", key: "discount", sortable: true },
          { label: "Prix Total", key: "ttl", sortable: true },
        ],
        [
          "#",
          { label: "Vendeur", key: "Nom_complet", sortable: true },
          { label: "Date", key: "rec_date", sortable: true },
          { label: "+10", key: "cat_1", sortable: true },
          { label: "7-10", key: "cat_2", sortable: true },
          { label: "2-7", key: "cat_3", sortable: true },
          { label: "-2", key: "cat_4", sortable: true },
          { label: "remise", key: "discount", sortable: true },
          { label: "Prix Total", key: "ttl", sortable: true },
        ],
        [
          "#",
          { label: "Vendeur", key: "Nom_complet", sortable: true },
          { label: "Date", key: "rec_date", sortable: true },
          { label: "Prix Total", key: "ttl", sortable: true },
          { label: "afficher Details", key: "action", sortable: true },
        ],
      ],
      cafeFields: [
        "#",
        { label: "Article", key: "item_name" },
        { label: "Categorie", key: "item_cat" },
        { label: "Prix Unitaire", key: "price" },

        { label: "Quantité", key: "qtt" },
        "prix",
      ],
    };
  },
  computed: {
    nombrePiscine() {
      var nb = Object.create(null);
      nb.cat_1 = this.items.reduce((a, b) => {
        return a + b.cat_1;
      }, 0);
      nb.cat_2 = this.items.reduce((a, b) => {
        return a + b.cat_2;
      }, 0);
      nb.cat_3 = this.items.reduce((a, b) => {
        return a + b.cat_3;
      }, 0);
      nb.cat_4 = this.items.reduce((a, b) => {
        return a + b.cat_4;
      }, 0);
      return nb;
    },
    nombrePiscineCov() {
      var nb = Object.create(null);
      nb.f = Object.create(null);
      nb.h = Object.create(null);

      nb.f.cat_1 = this.items
        .filter((el) => el.gender == "F")
        .reduce((a, b) => {
          return a + b.cat_1;
        }, 0);
      nb.f.cat_2 = this.items
        .filter((el) => el.gender == "F")
        .reduce((a, b) => {
          return a + b.cat_2;
        }, 0);
      nb.f.cat_3 = this.items
        .filter((el) => el.gender == "F")
        .reduce((a, b) => {
          return a + b.cat_3;
        }, 0);
      nb.f.cat_4 = this.items
        .filter((el) => el.gender == "F")
        .reduce((a, b) => {
          return a + b.cat_4;
        }, 0);

      nb.h.cat_1 = this.items
        .filter((el) => el.gender == "H")
        .reduce((a, b) => {
          return a + b.cat_1;
        }, 0);
      nb.h.cat_2 = this.items
        .filter((el) => el.gender == "H")
        .reduce((a, b) => {
          return a + b.cat_2;
        }, 0);
      nb.h.cat_3 = this.items
        .filter((el) => el.gender == "H")
        .reduce((a, b) => {
          return a + b.cat_3;
        }, 0);
      nb.h.cat_4 = this.items
        .filter((el) => el.gender == "H")
        .reduce((a, b) => {
          return a + b.cat_4;
        }, 0);

      return nb;
    },
    nombreHammam() {
      var nb = Object.create(null);
      nb.fcoll = this.items
        .filter((el) => el.gender == "F")
        .reduce((a, b) => {
          return a + b.coll + b.enf;
        }, 0);

      nb.findi = this.items
        .filter((el) => el.gender == "F")
        .reduce((a, b) => {
          return a + b.indi;
        }, 0);
      nb.hcoll = this.items
        .filter((el) => el.gender == "H")
        .reduce((a, b) => {
          return a + b.coll + b.enf;
        }, 0);

      nb.hindi = this.items
        .filter((el) => el.gender == "H")
        .reduce((a, b) => {
          return a + b.indi;
        }, 0);
      return nb;
    },
    total_price() {
      var nmb = 0;

      nmb = this.items.reduce((a, b) => a + b.ttl, 0);

      return nmb;
    },
    nummberPersons() {
      var nmb = 0;

      switch (this.selected_radio) {
        case "hammam":
          nmb = this.items.reduce((a, b) => a + b.coll + b.indi + b.enf, 0);
          break;
        case "piscine_cov":
        case "piscine":
          nmb = this.items.reduce(
            (a, b) => a + b.cat_1 + b.cat_2 + b.cat_3 + b.cat_4,
            0
          );

          break;
      }
      return nmb;
    },
    selected_fields() {
      var arr = [];
      switch (this.selected_radio) {
        case "hammam":
          arr = this.fields[0];
          break;
        case "piscine_cov":
          arr = this.fields[1];
          break;
        case "piscine":
          arr = this.fields[2];
          break;
        case "cafe":
          arr = this.fields[3];
          break;
      }
      return arr;
    },
    nummberRemises() {
      var nb = 0;
      nb = this.items.reduce((a, b) => a + b.remise, 0);
      return nb;
    }
  },
  watch: {
    selected_radio(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search(); // Automatically trigger the search method
      }
    },
  },
  methods: {
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    formatNumber: function (value) {
      if (isNaN(value)) return value;
      return new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    moment_date: function (date) {
      var moment = require("moment");
      return moment(date).format("YYYY-MM-DD");
    },
    showDetails(item) {
      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/details_caisse/cafeorder_items",
          {
            order_id: item.order_id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          this.cafeorder_items = data;
          console.log(data);
          //this.isbusy = false;
          this.$root.$emit("bv::show::modal", "cafeDetailsModal");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.is_off === "no") return "table-warning";
    },
    search() {
      const axios = require("axios");

      //this.isbusy = true;

      axios
        .post(
          this.$store.state.api_url + "/details_caisse/" + this.selected_radio,
          {
            ldate: this.moment_date(this.from_date),
            rdate: this.moment_date(this.to_date),
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          this.items = data;

          //this.isbusy = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },
  },
};
</script>


<style scoped>
#elements {
  margin-top: 2%;
}

.table-bordered {
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}

label {
  font-weight: bold;
}

.b-jumbotron {
  background-color: #f8f9fa;
  padding: 2rem;
}

.b-list-group-item {
  font-weight: bold;
  text-align: center;
}
</style>
