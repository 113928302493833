<template>
  <div class="container-fluid mt-4">
    <!-- Desktop/Large Screen Layout -->
    <b-row class="d-none d-lg-flex">
      <!-- Left Column: Items Table -->
      <b-col cols="12" lg="6">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h4 class="mb-0">Articles du Café</h4>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchQuery"
              placeholder="Rechercher..."
              size="sm"
              class="mr-2"
              style="max-width: 200px;"
            ></b-form-input>
            <b-button variant="primary" size="sm" @click="promptAddItem">
              <i class="fas fa-plus mr-1"></i> Ajouter un article
            </b-button>
          </div>
        </div>

        <b-card>
          <b-table
            :fields="fields"
            :items="filteredItems"
            primary-key="item_id"
            hover
            responsive
            small
            @row-clicked="selectItem"
            head-variant="light"
          >
            <template v-slot:cell(item_price)="data">
              <span>{{ formatPrice(data.item.item_price) }}</span>
            </template>

            <template v-slot:cell(actions)="row">
              <b-button size="sm" variant="danger" class="mr-2" @click.stop="deleteItem(row.item)">
                <i class="fas fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <!-- Right Column: Selected Item Details (Desktop) -->
      <b-col cols="12" lg="6">
        <div
          class="sticky-col"
          style="position:sticky; top:0; max-height:100vh; overflow:auto; padding-bottom:1rem;"
        >
          <b-card v-if="selectedItem" class="h-100 d-flex flex-column">
            <h5 class="mb-3">Détails de l'article</h5>
            <b-form @submit.prevent="updateSelectedItem">
              <b-form-group label="Nom" label-cols="3">
                <b-form-input v-model="selectedItemCopy.item_name" required></b-form-input>
              </b-form-group>

              <b-form-group label="Catégorie" label-cols="3">
                <v-select
                  :options="categories"
                  v-model="selectedItemCopy.item_cat"
                  taggable
                  placeholder="Sélectionner ou ajouter une catégorie"
                ></v-select>
              </b-form-group>

              <b-form-group label="Prix" label-cols="3">
                <b-form-input type="number" step="0.01" v-model.number="selectedItemCopy.item_price" required></b-form-input>
              </b-form-group>

              <b-form-group label="Image" label-cols="3">
                <div class="mb-2">
                  <img
                    v-if="selectedItemPreviewImage"
                    :src="selectedItemPreviewImage"
                    alt="Preview Image"
                    style="max-width:150px; max-height:150px;"
                    class="d-block mb-2 border rounded"
                  >
                  <img
                    v-else-if="selectedItemImage"
                    :src="selectedItemImage"
                    alt="Item Image"
                    style="max-width:150px; max-height:150px;"
                    class="d-block mb-2 border rounded"
                  >
                </div>
                <b-form-file @change="onDetailImageSelected" accept="image/*"></b-form-file>
              </b-form-group>

              <div class="mt-3 d-flex justify-content-between">
                <div>
                  <b-button variant="danger" @click="deleteSelectedItem">
                    <i class="fas fa-trash-alt"></i> Supprimer
                  </b-button>
                </div>
                <div>
                  <b-button variant="success" type="submit" class="mr-2">Modifier</b-button>
                  <b-button variant="secondary" @click="resetSelection">Annuler</b-button>
                </div>
              </div>
            </b-form>
          </b-card>
          <div v-else class="h-100 d-flex align-items-center justify-content-center flex-column text-muted">
            <p>Aucun article sélectionné.</p>
            <p>Veuillez sélectionner un article dans la liste pour afficher ses détails.</p>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- Mobile/Small Screen Layout -->
    <div class="d-block d-lg-none">
      <div v-if="!selectedItem">
        <!-- Show table if no item selected -->
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h4 class="mb-0">Articles du Café</h4>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchQuery"
              placeholder="Rechercher..."
              size="sm"
              class="mr-2"
              style="max-width: 200px;"
            ></b-form-input>
            <b-button variant="primary" size="sm" @click="promptAddItem">
              <i class="fas fa-plus mr-1"></i> Ajouter
            </b-button>
          </div>
        </div>

        <b-card>
          <b-table
            :fields="fields"
            :items="filteredItems"
            primary-key="item_id"
            hover
            responsive
            small
            @row-clicked="selectItem"
            head-variant="light"
          >
            <template v-slot:cell(item_price)="data">
              <span>{{ formatPrice(data.item.item_price) }}</span>
            </template>

            <template v-slot:cell(actions)="row">
              <b-button size="sm" variant="danger" class="mr-2" @click.stop="deleteItem(row.item)">
                <i class="fas fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
        </b-card>
      </div>

      <div v-else>
        <!-- Show details if item selected -->
        <b-card class="h-100 d-flex flex-column">
          <h5 class="mb-3">Détails de l'article</h5>
          <b-form @submit.prevent="updateSelectedItem">
            <b-form-group label="Nom" label-cols="3">
              <b-form-input v-model="selectedItemCopy.item_name" required></b-form-input>
            </b-form-group>

            <b-form-group label="Catégorie" label-cols="3">
              <v-select
                :options="categories"
                v-model="selectedItemCopy.item_cat"
                taggable
                placeholder="Sélectionner ou ajouter une catégorie"
              ></v-select>
            </b-form-group>

            <b-form-group label="Prix" label-cols="3">
              <b-form-input type="number" step="0.01" v-model.number="selectedItemCopy.item_price" required></b-form-input>
            </b-form-group>

            <b-form-group label="Image" label-cols="3">
              <div class="mb-2">
                <img
                  v-if="selectedItemPreviewImage"
                  :src="selectedItemPreviewImage"
                  alt="Preview Image"
                  style="max-width:150px; max-height:150px;"
                  class="d-block mb-2 border rounded"
                >
                <img
                  v-else-if="selectedItemImage"
                  :src="selectedItemImage"
                  alt="Item Image"
                  style="max-width:150px; max-height:150px;"
                  class="d-block mb-2 border rounded"
                >
              </div>
              <b-form-file @change="onDetailImageSelected" accept="image/*"></b-form-file>
            </b-form-group>

            <div class="mt-3 d-flex justify-content-between">
              <div>
                <b-button variant="danger" @click="deleteSelectedItem">
                  <i class="fas fa-trash-alt"></i> Supprimer
                </b-button>
              </div>
              <div>
                <b-button variant="success" type="submit" class="mr-2">Modifier</b-button>
                <b-button variant="secondary" @click="resetSelection">Annuler</b-button>
              </div>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>

    <!-- Modal for Adding a New Item -->
    <b-modal :id="modalId" title="Ajouter un article" @hide="resetModal" hide-footer>
      <b-container fluid>
        <b-form @submit.prevent="addNewItem">
          <b-form-group label="Nom" label-cols="3">
            <b-form-input v-model="modalItem.item_name" required placeholder="Nom de l'article"></b-form-input>
          </b-form-group>

          <b-form-group label="Catégorie" label-cols="3">
            <v-select
              :options="categories"
              taggable
              v-model="modalItem.item_cat"
              placeholder="Sélectionner ou ajouter une catégorie"
            ></v-select>
          </b-form-group>

          <b-form-group label="Prix" label-cols="3">
            <b-form-input type="number" step="0.01" v-model.number="modalItem.item_price" required></b-form-input>
          </b-form-group>

          <b-form-group label="Image" label-cols="3">
            <b-form-file @change="onModalImageSelected" accept="image/*"></b-form-file>
            <div v-if="modalPreviewImage" class="mt-2">
              <img :src="modalPreviewImage" alt="Preview" style="max-width:100px; max-height:100px;">
            </div>
          </b-form-group>

          <div class="d-flex justify-content-end mt-3">
            <b-button variant="success" type="submit">
              <i class="fas fa-check"></i> Ajouter
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "CafeItemsComponent",
  components: { vSelect },
  data() {
    return {
      items: [],
      categories: [],
      fields: [
        { key: "item_id", label: "ID", sortable: true },
        { key: "item_name", label: "Nom", sortable: true },
        { key: "item_cat", label: "Catégorie", sortable: true },
        { key: "item_price", label: "Prix", sortable: true },
        { key: "actions", label: "", class: "text-right" },
      ],
      selectedItem: null,
      selectedItemCopy: null,
      selectedItemImage: null,
      selectedItemPreviewImage: null,
      modalId: "add-item-modal",
      modalItem: {
        item_name: "",
        item_price: 0,
        item_cat: "",
        item_image: null,
      },
      modalPreviewImage: null,
      detailImageFile: null,
      searchQuery: "",
    };
  },
  computed: {
    filteredItems() {
      if (!this.searchQuery) {
        return this.items;
      }
      const query = this.searchQuery.toLowerCase();
      return this.items.filter(item =>
        (item.item_name && item.item_name.toLowerCase().includes(query)) ||
        (item.item_cat && item.item_cat.toLowerCase().includes(query))
      );
    },
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      axios
        .get(`${this.$store.state.api_url}/cafe_items`, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((response) => {
          this.items = response.data;
          const cats = new Set(this.items.map((i) => i.item_cat));
          this.categories = Array.from(cats);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.error();
          } else {
            console.error(err);
          }
        });
    },
    selectItem(item) {
      this.selectedItem = item;
      this.selectedItemCopy = { ...item, item_image: null };
      this.selectedItemPreviewImage = null;
      this.fetchItemImage(item.item_id);
    },
    resetSelection() {
      this.selectedItem = null;
      this.selectedItemCopy = null;
      this.selectedItemImage = null;
      this.selectedItemPreviewImage = null;
      this.detailImageFile = null;
    },
    onDetailImageSelected(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result.split(",")[1];
          this.selectedItemCopy.item_image = base64Data;
          this.selectedItemPreviewImage = e.target.result; // Show preview of new image
        };
        reader.readAsDataURL(file);
      } else {
        this.selectedItemCopy.item_image = null;
        this.selectedItemPreviewImage = null;
      }
    },
    updateSelectedItem() {
      if (!this.selectedItemCopy) return;

      axios
        .put(`${this.$store.state.api_url}/cafe_items/${this.selectedItemCopy.item_id}`, this.selectedItemCopy, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then(() => {
          this.$bvToast.toast("Modifié avec succès", {
            title: "Succès",
            variant: "success",
            solid: true,
          });
          this.fetchItems();
          this.selectedItem = { ...this.selectedItemCopy, item_image: null };
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.error();
          } else {
            this.$bvToast.toast("Erreur lors de la modification", {
              variant: "danger",
              solid: true,
            });
            console.error(err);
          }
        });
    },
    promptAddItem() {
      this.modalItem = {
        item_name: "",
        item_price: 0,
        item_cat: "",
        item_image: null,
      };
      this.modalPreviewImage = null;
      this.$root.$emit("bv::show::modal", this.modalId);
    },
    onModalImageSelected(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result.split(",")[1];
          this.modalItem.item_image = base64Data;
          this.modalPreviewImage = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.modalItem.item_image = null;
        this.modalPreviewImage = null;
      }
    },
    addNewItem() {
      axios
        .post(`${this.$store.state.api_url}/cafe_items`, this.modalItem, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then(() => {
          this.$bvToast.toast("Ajouté avec succès", {
            title: "Succès",
            variant: "success",
            solid: true,
          });
          this.fetchItems();
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.error();
          } else {
            this.$bvToast.toast("Erreur lors de l'ajout", {
              variant: "danger",
              solid: true,
            });
            console.error(err);
          }
        })
        .finally(() => {
          this.$root.$emit("bv::hide::modal", this.modalId);
        });
    },
    resetModal() {
      this.modalItem = {
        item_name: "",
        item_price: 0,
        item_cat: "",
        item_image: null,
      };
      this.modalPreviewImage = null;
    },
    fetchItemImage(item_id) {
      axios
        .get(`${this.$store.state.api_url}/cafe_items/${item_id}/image`, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
          responseType: 'arraybuffer'
        })
        .then((response) => {
          if (response.status === 200) {
            const base64 = btoa(
              new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            this.selectedItemImage = `data:image/png;base64,${base64}`;
          } else {
            this.selectedItemImage = null;
          }
        })
        .catch((err) => {
          this.selectedItemImage = null;
          if (err.response && err.response.status !== 404) {
            console.error("Error fetching image:", err);
          }
        });
    },
    deleteItem(item) {
      this.$bvModal
        .msgBoxConfirm("Confirmez la suppression de l'article?", {
          title: "Confirmez SVP",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          footerClass: "p-2",
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios
              .delete(`${this.$store.state.api_url}/cafe_items/${item.item_id}`, {
                headers: { Authorization: "Bearer " + this.$store.state.token },
              })
              .then(() => {
                this.$bvToast.toast("Supprimé avec succès", {
                  title: "Succès",
                  variant: "success",
                  solid: true,
                });
                this.fetchItems();
                if (this.selectedItem && this.selectedItem.item_id === item.item_id) {
                  this.resetSelection();
                }
              })
              .catch((err) => {
                if (err.response && err.response.status === 401) {
                  this.error();
                } else {
                  this.$bvToast.toast("Erreur lors de la suppression", {
                    variant: "danger",
                    solid: true,
                  });
                  console.error(err);
                }
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteSelectedItem() {
      if (!this.selectedItem) return;
      this.deleteItem(this.selectedItem);
    },
    formatPrice(value) {
      return `${Number(value).toFixed(2)} €`;
    },
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.container-fluid {
  max-width: 1400px;
}

[v-cloak] {
  display: none;
}

img {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.b-table thead th {
  vertical-align: middle;
}
</style>
