<template>
  <div id="app">
    <!-- Navigation Bar -->
    <b-navbar type="dark" :variant="navColor">
      <b-navbar-brand href="#">Jerry Accounting 2.0</b-navbar-brand>
      <b-navbar-nav class="ml-auto" v-if="showReturnButton">
        <b-button variant="outline-light" @click="goToMenu">Retour</b-button>
      </b-navbar-nav>
    </b-navbar>

    <!-- Main Content -->
    <b-container fluid class="py-4">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </b-container>
  </div>
</template>

<script>
import Router from "@/router/index.js";

export default {
  data() {
    return {
      navColor: "primary", // Default color for navbar
      showReturnButton: false, // Controls the display of the "Retour" button
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // Dynamically control "Retour" button visibility
        this.showReturnButton =
          this.$route.path !== "/Menu" && this.$route.path !== "/";
      },
    },
  },
  methods: {
    goToMenu() {
      Router.push("/Menu");
    },
  },
};
</script>

<style scoped>
/* General Styles */
body {
  background: linear-gradient(to bottom right, #f4f8fa, #dce3e8);
  font-family: "Poppins", sans-serif;
  color: #4a4a4a;
}

/* Navbar Customization */
#app .b-navbar {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#app .b-navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

#app .b-navbar-nav .b-button {
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

#app .b-navbar-nav .b-button:hover {
  background-color: #f44336;
  transform: scale(1.05);
}

/* Container Styling */
.b-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, 
.fade-leave-to {
  opacity: 0;
}

/* Global Theme */
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
}
</style>
