<template>
  <div class="jumbotron d-flex justify-content-center align-items-center vh-100">
    <div class="menu-wrapper">
      <!-- Responsive grid: 2 columns on small screens, 3 on large -->
      <div class="row row-cols-2 row-cols-lg-3 g-4">
        <MenuItem v-for="(item, index) in menuItems" :key="index" :path="item.path" :icon="item.icon"
          :altText="item.altText" :caption="item.caption" :iconColor="item.iconColor" :hoverColor="item.hoverColor" />
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "../components/MenuItem.vue";

export default {
  name: "MenuComponent",
  components: {
    MenuItem,
  },
  data() {
    return {
      menuItems: [
        {
          path: "/records",
          icon: "fas fa-file-alt",
          altText: "Caisse",
          caption: "Enregistrement<br />Modification<br />Consultation",
          iconColor: "#007bff",
          hoverColor: "#007bff",
        },
        {
          path: "/records_printing",
          icon: "fas fa-print",
          altText: "Imprimante",
          caption: "Impression",
          iconColor: "#28a745",
          hoverColor: "#28a745",
        },
        {
          path: "/remises",
          icon: "fas fa-percentage",
          altText: "Réduction",
          caption: "Remises",
          iconColor: "#ffc107",
          hoverColor: "#ffc107",
        },
        {
          path: "/salaries",
          icon: "fas fa-wallet",
          altText: "Portefeuille",
          caption: "Registre des Salaires",
          iconColor: "#dc3545",
          hoverColor: "#dc3545",
        },
        {
          path: "/details",
          icon: "fas fa-search",
          altText: "Loupe",
          caption: "Détails des caisses",
          iconColor: "#17a2b8",
          hoverColor: "#17a2b8",
        },
        {
          path: "/hr",
          icon: "fas fa-users-cog",
          altText: "Employés",
          caption: "Ressources Humaines",
          iconColor: "#343a40",
          hoverColor: "#343a40",
        },
        // New menu item for café items
        {
          path: "/CafeMenu",
          icon: "fas fa-coffee",
          altText: "Café",
          caption: "Gestion des Articles du Café",
          iconColor: "#6f42c1", // Purple example
          hoverColor: "#6f42c1", // Purple shadow
        },
      ],
    };
  },
};
</script>

<style scoped>
.jumbotron {
  background: #f8f9fa;
  padding: 20px;
}

.vh-100 {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-wrapper {
  background: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
</style>
