import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Axios from 'axios'
import vSelect from 'vue-select'

//import mouseMenu from 'vue-mouse-menu'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './registerServiceWorker'
import 'vue-select/dist/vue-select.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome CSS



Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('v-select', vSelect)

//Vue.use(mouseMenu)


Vue.prototype.$http = Axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
