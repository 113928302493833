<template>
  <div id="elements" style="width: 100%">
    <b-jumbotron>
      <b-row>
        <b-col>
          <b-alert
            show
            variant="success"
            class="pb-4"
            style="text-align: center"
          >
            Reductions
            <b-button
              style="float: right"
              variant="primary"
              @click="prompt_Add()"
              >Ajouter</b-button
            >
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <table class="col-12">
          <thead>
            <th></th>
            <th>1ere reduction</th>
            <th>2eme reduction</th>
            <th>3eme reduction</th>
          </thead>
          <tbody v-if="isReady">
            <tr>
              <td>Hammam</td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  :value="reductions[0].red1"
                  class="form-control"
                  @change="updateValue($event, 0, 1)"
                />
              </td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[0].red2"
                  @change="updateValue($event, 0, 2)"
                />
              </td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[0].red3"
                  @change="updateValue($event, 0, 3)"
                />
              </td>
            </tr>
            <tr>
              <td>Piscine cov</td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[1].red1"
                  @change="updateValue($event, 1, 1)"
                />
              </td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[1].red2"
                  @change="updateValue($event, 1, 2)"
                />
              </td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[1].red3"
                  @change="updateValue($event, 1, 3)"
                />
              </td>
            </tr>
            <tr>
              <td>Piscine non-cov</td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[2].red1"
                  @change="updateValue($event, 2, 1)"
                />
              </td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[2].red2"
                  @change="updateValue($event, 2, 2)"
                />
              </td>
              <td>
                <input
                  type="number"
                  max="100"
                  min="0"
                  class="form-control"
                  :value="reductions[2].red3"
                  @change="updateValue($event, 2, 3)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-success btn-block mt-4" @click="updateReds">Sauvegarder </button>
      </b-row>
    </b-jumbotron>
  </div>
</template>



<script>
export default {
  name: "Reductions-component",
  data() {
    return {
      isReady: false,
      reductions: [],
    };
  },

  methods: {
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    updateValue(val, id, redNumber) {
      const red = "red" + redNumber;
      this.reductions[id][red] = parseFloat(val.target.value);
      console.log(this.reductions);
    },
    updateReds() {
      const axios = require("axios");
    
      axios
        .post(this.$store.state.api_url + "/reductions", this.reductions, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log("Success :", response);
          //this.isbusy = false;
          this.$bvToast.toast("Modifié avec succée", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
          // location.reload();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        })
    },
  },
  mounted() {
    const axios = require("axios");
    axios
      .get(
        this.$store.state.api_url + "/reductions",

        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        this.reductions = data;
        this.isReady = true;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log("uNauthorized request");
          console.log(err);
          this.error();
        } else {
          console.log("error : " + err);
        }
      });
  },
};
</script>

<style scoped>
#elements {
  margin-top: 3%;
  width: 50%;
}
.table-responsive {
  background-color: white;
}
</style>