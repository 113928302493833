<template>
  <div class="login-wrapper border border-light shadow">
    <form class="form-signin" @submit.prevent="login">
      <h2 class="form-signin-heading text-center mb-4">Connexion</h2>
      <div class="form-group">
        <label for="userName" class="sr-only">Adresse email</label>
        <input v-model="form.login" id="userName" type="text" class="form-control" placeholder="Nom d'utilisateur"
          aria-label="Nom d'utilisateur" required autofocus />
      </div>
      <div class="form-group">
        <label for="inputPassword" class="sr-only">Mot de passe</label>
        <input v-model="form.password" type="password" id="inputPassword" class="form-control"
          placeholder="Mot de passe" aria-label="Mot de passe" required />
      </div>
      <div class="text-danger mb-3" v-if="error">{{ error }}</div>
      <button class="btn btn-lg btn-primary btn-block" type="submit"
        :disabled="!form.login || !form.password || loading">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="!loading">Se connecter</span>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginComponent",
  data() {
    return {
      form: {
        login: "",
        password: ""
      },
      error: "",
      loading: false
    };
  },
  methods: {
    login() {
      this.error = "";
      this.loading = true;

      const { login, password } = this.form;

      this.$store
        .dispatch("login", { login, password })
        .then((status) => {
          if (status === 201) {
            console.log("Connexion réussie");
            this.$router.push("/Menu");
          } else {
            throw new Error("Identifiants invalides");
          }
        })
        .catch((err) => {
          this.error = "Échec de la connexion : email ou mot de passe incorrect.";
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
body {
  background: #605b56;
}

.login-wrapper {
  background: #fff;
  width: 100%;
  max-width: 400px;
  padding: 30px;
  margin: 10% auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.form-signin {
  width: 100%;
}

.form-signin .form-control {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.form-signin .form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.btn {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
}

.spinner-border {
  margin-right: 5px;
}

.text-danger {
  font-size: 0.9rem;
}
</style>
